import React from 'react';
import work_reel from '../assets/works_reel.mp4';
import Logo from '../assets/kr_logo.svg';
import'../styles-css/HeroComingsoon.css';

const HeroComingsoon = () => {
    return(
        <div className='hero'>
            <div className='texture-geo'></div>
            <div className='overlay'></div>
            <video src={work_reel} autoPlay loop muted />
            <div className='content'>
                <img className='logotype'
                src={Logo} alt='logotipo imagen' />
                <h1 className='titles'> Próximamente </h1>
                <h3 className='subtitle2'> Continuamos construyendo esta página, ¡Espérala pronto!</h3>
            </div>
        </div>
    )
}

export default HeroComingsoon;