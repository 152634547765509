import './App.css';
import HeroComingsoon from './components/HeroComingsoon';

function App() {
  return (
    <div className="App">
      <HeroComingsoon/>
    </div>
  );
}

export default App;
